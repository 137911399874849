import { parse } from "date-fns";
import { LOCAL_STORAGE_KEY_CONTRACTS, LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_CUT_DATE, LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_AFTER_CUT_DATE, LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_BEFORE_CUT_DATE } from "../const/localStorageKeys";
import { Parameter } from "../models/parameter/parameter";
import { Base } from "./base";
import { Contract } from "../models/bank/contract";

export class BasePayment extends Base {
  private acceptDueDaysAfterDueDate =
    parseInt(
      this.getLocalStorageItem<Parameter>(
        LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_AFTER_CUT_DATE
      ).value
    );

  private acceptDueDaysBeforeDueDate =
    parseInt(
      this.getLocalStorageItem<Parameter>(
        LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_BEFORE_CUT_DATE
      ).value
    );

  private cutDate = parse(this.getLocalStorageItem<Parameter>(
    LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_CUT_DATE
  ).value, 'dd/MM/yyyy', new Date());

  public contract = this.getLocalStorageItem<Contract>(
    LOCAL_STORAGE_KEY_CONTRACTS
  );

  get acceptDueDays(): number {
    if (new Date(this.contract.initDate) > this.cutDate) {
      return this.acceptDueDaysAfterDueDate;
    } else {
      return this.acceptDueDaysBeforeDueDate;
    }
  }

}