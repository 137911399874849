import { Component } from '@angular/core';
import { Contract } from 'src/app/models/bank/contract';
import { Payment } from 'src/app/models/bank/payment';
import { BankService } from 'src/app/services/bank.service';
import { firstValueFrom } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Parameter } from 'src/app/models/parameter/parameter';
import { BaseComponent } from 'src/app/@base/base.component';
import { BasePayment } from 'src/app/@base/base-payment';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent extends BasePayment{

  public installments: Payment[] = [];
  public date: string | Date = new Date();
  public allowPayment: boolean = true;
  public loading: boolean = false;
  public messageNotAllowed: string;

  constructor(private bankService: BankService, private datePipe: DatePipe){
    super();
    this.getAllPayment();
    this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
  }

  ngOnInit(): void {}

  private getAllPayment(): void {

    this.bankService.emitContractSelected.subscribe((contract: Contract) => {

      this.contract = contract
      this.loading = true
      this.allowPayment = true;

      firstValueFrom(this.bankService.get<Payment[]>(`${contract.contractNumber}/instalments`))
        .then((resp: Payment[]) => this.installments = resp)
        .then(() => this.checkData())
        .then(() => this.checkDates())
        .finally(() => this.loading = false)
    
    })
    
  }

  private checkDates(): void {
    let days = -1 * this.acceptDueDays
    
    if(this.installments.find(x => this.dateDiffInDays(new Date(this.date), new Date(x.dueDate)) < days)){
      this.messageNotAllowed = "Favor entrar em contato com a nossa Central de Relacionamento, através do telefone (11) 2088-7700, para obter mais informações do contrato."
      this.allowPayment = false;
    }
  }

  private checkData(): void {
    if(this.installments.length == 0){
      this.messageNotAllowed = "Não há parcelas disponíveis para realizar o pagamento.";
      this.allowPayment = false;
    }
  }

  public dateDiffInDays(a: Date, b: Date): number {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

    
}
