<app-bank-header [firstDate]="firstDate" [LastDate]="LastDate">

  <div class="bank-header">
    <app-bank-amount (emitFirstDate)="onFirstDate($event)" (emitLastDate)="onLastDate($event)">

    </app-bank-amount>
  </div>


  <div *ngIf="!loading; else containerLoading" class="bank-content">
    <span class="section-title title estricta" data-cy="bankDigitalBookletTitle"> CARNÊ DIGITAL </span>

    <div *ngIf="!isSmallScreen">
      <div class="legend">
        <mat-checkbox color="primary" class="checkbox" (change)="onCheckBoxChange($event)" [indeterminate]="rowsSelected.length > 0 && !isAllSelected"  #checkboxes></mat-checkbox>
        <div class="legend-item">
          <span class="legend-icon back-blue"></span>
          <span class="legend-text">Paga</span>
        </div>
        <div class="legend-item">
          <span class="legend-icon back-green"></span>
          <span class="legend-text">Em Dia</span>
        </div>
        <div class="legend-item">
          <span class="legend-icon back-yellow"></span>
          <span class="legend-text">Em atraso a menos de {{acceptDueDays}} dias</span>
        </div>
        <div class="legend-item">
          <span class="legend-icon back-red"></span>
          <span class="legend-text">Em atraso a mais de {{acceptDueDays}} dias</span>
        </div>
      </div>
    </div>

    <div>

      <div [ngClass]="!messageNotAllowed ? 'content' : ''">
        <div *ngIf="isSmallScreen" [ngClass]="isSmallScreen ? 'orderLegend' : '' ">
          <div class="legend">
            <div class="legend-item">
              <span class="legend-icon back-blue"></span>
              <span class="legend-text">Paga</span>
            </div>
            <div class="legend-item">
              <span class="legend-icon back-green"></span>
              <span class="legend-text">Em Dia</span>
            </div>
            <div class="legend-item">
              <span class="legend-icon back-yellow"></span>
              <span class="legend-text">Em atraso a menos de {{acceptDueDays}} dias</span>
            </div>
            <div class="legend-item">
              <span class="legend-icon back-red"></span>
              <span class="legend-text">Em atraso a mais de {{acceptDueDays}} dias</span>
            </div>
          </div>
          <mat-checkbox color="primary" class="checkbox" (change)="onCheckBoxChange($event)" [indeterminate]="rowsSelected.length > 0 && !isAllSelected" #checkboxes
            data-cy="bankDigitalBookletSelectAllInstallments"></mat-checkbox>Selecionar todas as parcelas

        </div>

        <app-table-mobile *ngIf="table && !messageNotAllowed" [ngClass]="isSmallScreen ? 'app-table-mobile-order' : '' "
          [table]="table" [rowSelected]="rowsSelected" (emitSelectChanged)="onInstallmentCheck($event)"
          (emitSelectAllChanged)="onAllInstallmentsCheck($event)" [disableCondition]="disableCheckboxCondition"
          [showPaymentDay]="true">
        </app-table-mobile>

        <app-bank-summary [contract]="contract.contractNumber" [installments]="rowsSelected" [totalRows]="totalRows"
          [allowPayment]="table.checkbox" [btnColor]="getBtnColor()" [txtBtnGenerateBill]="getBtnText()"
          [icon]="getBtnIcon()" [allSelected]="isAllSelected"
          (emitDocumentCreated)="getAllPayment(false); deselectAll()">

          <div class="bank-summary-header">
            <div class="flex card-header">
              <span class="card-title estricta uppercase">Resumo</span>
              <div class="card-header-info">
                <mat-icon class="red icon">info</mat-icon>
                <div>
                  <span class="red text">Cálculo para</span>
                  <span class="red text" [attr.data-cy]="'Data pagamento'">{{date | date : 'dd/MM/yyyy'}}</span>
                </div>
              </div>
            </div>
            <mat-divider style="margin: 15px -22px 0 -22px"></mat-divider>
            <div class="selected-installments">
              <span class="text uppercase payment-rows-selected" data-cy="installmentsSelectedLabel">
                Parcelas selecionadas:
              </span>

              <mat-chip-listbox *ngIf="rowsSelected.length > 0; else noOneSelect" selectable="false"
                data-cy="installmentsSelected">
                <mat-chip-option *ngFor="let row of rowsSelectedSorted()"
                  [attr.data-cy]="'installmentsSelected' + row.number">
                  <span> {{row.number}} </span>
                  <!-- <button matChipRemove (click)="onInstallmentCheck({ selected: false, row: row });">
                    <mat-icon>clear</mat-icon>
                  </button> -->
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            <mat-divider style="margin: 15px -22px 0 -22px"></mat-divider>
            <ng-template #noOneSelect> - </ng-template>
          </div>

          <div class="bank-summary-not-allowed">
            <span>{{messageNotAllowed}}</span>
          </div>

        </app-bank-summary>
      </div>
    </div>
  </div>

</app-bank-header>

<ng-template #containerLoading>
  <div class="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
</ng-template>