import { SelectionModel } from "@angular/cdk/collections";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Payment } from "src/app/models/bank/payment";
import { Paginator, Table } from "src/app/models/table";
import { CurrencyPipe } from "@angular/common";
import { MatCheckbox } from "@angular/material/checkbox";
import { Parameter } from "src/app/models/parameter/parameter";
import { BasePayment } from "src/app/@base/base-payment";

@Component({
  selector: "app-table-mobile",
  templateUrl: "./table-mobile.component.html",
  styleUrls: ["./table-mobile.component.scss"],
  providers: [CurrencyPipe],
})
export class TableMobileComponent extends BasePayment implements DoCheck, AfterViewInit {
  @ViewChildren("checkboxes") checkboxes: QueryList<MatCheckbox>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() datacy: string;
  @Input() table: Table<any>;
  @Input() disableCondition: any = false;
  @Input() rowSelected: Payment[] = [];
  @Input() showPaymentDay: boolean = false;
  @Output() emitSelectChanged: EventEmitter<any> = new EventEmitter();
  @Output() emitSelectAllChanged: EventEmitter<any> = new EventEmitter();
  @Output() emitActionChange: EventEmitter<any> = new EventEmitter();
  @Output() emitActionDelete: EventEmitter<any> = new EventEmitter();
  @Output() emitActionView: EventEmitter<any> = new EventEmitter();
  @Output() emitPaginatorChanged: EventEmitter<Paginator> = new EventEmitter();
  @Output() emitRowDoubleClick: EventEmitter<any> = new EventEmitter();
  @Output() emitDataChange: EventEmitter<any> = new EventEmitter();
  @Output() emitButtonExcelClick: EventEmitter<any> = new EventEmitter();

  public maxWidth: string;
  public height: string;

  public keys: string[];
  public values: string[];

  public selection = new SelectionModel<any>(true, []);

  public date: string | Date = new Date();

  public dataSource: MatTableDataSource<any>;
  public isSmallScreen: boolean;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private currencyPipe: CurrencyPipe
  ) {
    super();
  }


  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe((result) => {
        this.isSmallScreen = result.matches;
      });
  }
  ngDoCheck(): void {
    document.documentElement.style.setProperty(
      "--quantidade",
      this.table.headers.length + ""
    );

    this.dataSource = new MatTableDataSource<any>(this.table.data);

    this.keys = this.table.headers.map((x) => x.key);
    this.values = this.table.headers.map((x) => x.value);

    this.height = this.table.style?.table?.height
      ? this.table.style.table.height
      : "100%";
    this.maxWidth = this.table.style?.table?.maxWidth
      ? this.table.style.table.maxWidth
      : "100%";

    if (this.table.checkbox) {
      this.putCheckbox();
    }

    if (this.table.actions) {
      this.putActions();
    }

    if (this.table.buttons) {
      this.putButtons();
    }

    if (this.table.data.length == 0) {
      this.rowSelected = [];
    }

    if (this.paginator) {
      if (this.table.paginator.index == undefined) {
        this.table.paginator.index = 1;
        return;
      }

      this.paginator.pageIndex = this.table.paginator.index - 1;
    }
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator._intl.itemsPerPageLabel = "Itens por página";
      this.paginator._intl.nextPageLabel = "Próxima página";
      this.paginator._intl.previousPageLabel = "Página anterior";
      this.paginator._intl.firstPageLabel = "Primeira página";
      this.paginator._intl.lastPageLabel = "Última página";
      this.paginator._intl.getRangeLabel = this.displayText;
    }

    this.dataSource.paginator = this.paginator;
  }

  formatValue(value: number): string {
    if (value < 0) {
      return Math.abs(value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    } else {
      return `- ${value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}`;
    }
  }

  calculateDifference(row: any): number {
    return row.originalValue - (row.paidValue || row.total);
  }

  public isSelected(row): boolean {
    return this.rowSelected.filter((x) => x.number == row.number).length > 0;
  }

  private displayText(page: number, pageSize: number, length: number): string {
    if (length == 0 || pageSize == 0) {
      return "Sem resultados";
    }

    return `Página: ${page + 1} de ${Math.ceil(length / pageSize)}`;
  }

  shouldDisableCheckbox(row): boolean {
    // A checkbox será desabilitada se a condição for verdadeira
    if (this.disableCondition) {
      return this.disableCondition(row);
    }
    return false;
  }

  public isAllSelected() {
    const numSelected = this.rowSelected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      // this.selection.clear();
      this.onAllRowsChange(false);
      return;
    }

    this.onAllRowsChange(true);
    // this.selection.select(...this.dataSource.data);
  }

  public footerKeys(): string[] {
    if (!this.table.checkbox) {
      if (!this.table.footer.amount.display) {
        return this.keys;
      }

      return this.keys.filter((x) => x !== this.table.footer.amount.column);
    }

    return this.keys;
  }

  public footerValue(key: string): string {
    if (this.table.footer) {
      let footer = this.table.footer.columns.find((x) => x.column == key);
      let prefix = footer.prefix ? footer.prefix : "";
      let transform = footer.transform ? footer.transform : null;

      if (footer.operation == "sum") {
        let sum =
          prefix +
          " " +
          this.table.data
            .map((x) => parseFloat(x[key]))
            .reduce((partialSum, a) => {
              if (!Number.isNaN(a)) {
                return partialSum + a;
              }
              return partialSum;
            }, 0)
            .toFixed(2);

        if (transform) {
          return transform(prefix + " " + sum);
        }
        return prefix + " " + sum;
      }

      if (footer.operation == "quantity") {
        if (transform) {
          return transform(
            prefix + " " + footer.prefix + this.table.data.length
          );
        }
        return prefix + " " + footer.prefix + this.table.data.length;
      }
    }

    return "";
  }

  public showAmount(index: number): boolean {
    if (!this.table.checkbox) {
      if (index == 0) {
        return true;
      }
    }

    return false;
  }

  public showFooterData(key: string): boolean {
    if (this.table.footer.columns.map((x) => x.column).includes(key)) {
      return true;
    }

    return false;
  }

  public headerStyle(key: string): object {
    let style = this.table.style?.headers?.find((x) => x.key == key);

    if (!style?.styles) {
      return {};
    }

    return style.styles[0].class;
  }

  public dataStyle(key: string, element: any): object {
    let style = this.table.style?.data.find((x) => x.key == key);

    if (!style?.styles) {
      return {};
    }

    const rule = style.styles.find((x) => x.rule && x.rule(element[key]));

    if (rule) {
      return rule.class;
    }

    return style.styles[0].class;
  }

  public dataChildStyle(key: string, child: any): object {
    let style = this.table.style?.data.find((x) => x.key == key);

    if (!(style?.styles?.filter((x) => x.childClass).length > 0)) {
      return {};
    }

    const rule = style?.styles
      ?.filter((x) => x.childClass)
      .find((x) => x.rule && x.rule(child));

    if (rule) {
      return rule.class;
    }

    return style.styles[0].childClass.class;
  }

  public dataPrefix(key: string): string {
    let style = this.table.style?.data.find((x) => x.key == key);

    if (style) {
      return this.table.style.data.find((x) => x.key == key).prefix;
    }

    return "";
  }

  public dataTransform(key: string): Function {
    let style = this.table.style?.data.find((x) => x.key == key);

    if (style) {
      return this.table.style.data.find((x) => x.key == key).transform;
    }

    return null;
  }

  public render(value, key: string): string {
    if (value !== null && value !== "") {
      var transform = this.dataTransform(key);

      if (transform == null) {
        return value;
      }

      return transform(value);
    }

    return "-";
  }

  public isArray(value): boolean {
    return Array.isArray(value);
  }

  public isEditable(key: string): boolean {
    return this.table.style?.data?.find((x) => x.key == key)?.editable;
  }

  public align(key?: string): string {
    if (this.table.style?.table?.align) {
      if (
        key &&
        this.table.style.table.align.column.find((x) => x.key === key)
      ) {
        return this.table.style?.table?.align.column.find((x) => x.key === key)
          .align;
      }

      if (this.table.style.table.align.default) {
        return this.table.style?.table?.align.default;
      }
    }

    return "left";
  }

  public putCheckbox(): void {
    this.values.unshift("select");
    this.keys.unshift("select");
  }

  public putActions(): void {
    this.values.unshift("actions");
    this.keys.unshift("actions");
  }

  public putButtons(): void {
    this.values.unshift("buttons");
    this.keys.unshift("buttons");
  }

  public dataClass(disCol, element): string {
    return this.isSelected(element)
      ? "active " + this.align(disCol)
      : this.align(disCol);
  }

  public onDataChange(event: any, row: any, key: string): void {
    this.emitDataChange.emit({ value: event.target.value, key, row });
  }

  public onRowChange(row: any, event, isSelectAll: boolean = false): void {
    this.emitSelectChanged.emit({ row, selected: event.checked, source: event.source, isAllSelected: isSelectAll });
  }

  public onAllRowsChange(selected: boolean): void {
    this.emitSelectAllChanged.emit({ selected });
  }

  public onActionChange(event: object): void {
    this.emitActionChange.emit(event);
  }

  public onActionDelete(event: object): void {
    this.emitActionDelete.emit(event);
  }

  public onActionView(event: object): void {
    this.emitActionView.emit(event);
  }

  public onButtonExcelClicked(): void {
    this.emitButtonExcelClick.emit();
  }

  public handlePageEvent(evento): void {
    this.emitPaginatorChanged.emit({
      index: evento.pageIndex + 1,
      pageSize: evento.pageSize,
    });
  }

  preventSingleClick = false;
  timer: any;
  delay: Number;

  singleClick(event) {
    this.preventSingleClick = false;
    const delay = 200;
    this.timer = setTimeout(() => {
      if (!this.preventSingleClick) {
        return;
      }
    }, delay);
  }

  doubleClick(event, row) {
    this.preventSingleClick = true;
    clearTimeout(this.timer);
    this.emitRowDoubleClick.emit(row);
  }

  public returnLine(row: any): number {
    return this.table.data.indexOf(row) + 1;
  }

  public getActionPermissionUpdate(): boolean {
    if (typeof this.table.actions !== "boolean") {
      return this.table.actions?.update;
    }

    return true;
  }

  public getActionPermissionDelete(): boolean {
    if (typeof this.table.actions !== "boolean") {
      return this.table.actions?.delete;
    }

    return true;
  }

  public getButtonPermissionDelete(): boolean {
    if (typeof this.table.buttons !== "boolean") {
      return this.table.buttons.delete;
    }

    return true;
  }

  public getButtonPermissionRead(): boolean {
    if (typeof this.table.buttons !== "boolean") {
      return this.table.buttons?.read;
    }

    return true;
  }

  public getActionPermission(): boolean {
    return !(
      this.getActionPermissionUpdate() || this.getActionPermissionDelete()
    );
  }

  public getReadOnly(key: string): boolean {
    return this.table.style.data.find((x) => x.key == key).readonly;
  }

  public detectar_mobile(): boolean {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return true;
    }
  }

  // public getColor(dueDate: string, paymentDate: string, numve: string): string {
    
  //   if(paymentDate){
  //     return "legend-icon back-blue";
  //   }
  //   else if (      
  //     this.dateDiffInDays(new Date(dueDate), new Date(this.date)) < -15
  //   ) {
  //     return "legend-icon back-green";
  //   }
  //   else if (
  //     this.dateDiffInDays(new Date(dueDate), new Date(this.date)) <= 15 &&
  //     this.dateDiffInDays(new Date(dueDate), new Date(this.date)) > 0
  //   ) {
  //     return "legend-icon back-yellow";
  //   } else {
  //     return "legend-icon back-red";
  //   }
  // }



  
  // public getColor(dueDate: string, paymentDate: string, numve: string): string {
    
  //   if(paymentDate){
  //     return "legend-icon back-blue";
  //   }
  //   else if (      
  //     this.dateDiffInDays(new Date(dueDate), new Date(this.date)) < (this.acceptDueDays *-1)
  //   ) {
  //     return "legend-icon back-green";
  //   }
  //   else if (
  //     this.dateDiffInDays(new Date(dueDate), new Date(this.date)) <= this.acceptDueDays &&
  //     this.dateDiffInDays(new Date(dueDate), new Date(this.date)) > 0
  //   ) {
  //     return "legend-icon back-yellow";
  //   } else {
  //     return "legend-icon back-red";
  //   }
  // }

  public getColor(dueDate: string, paymentDate: string, numve: string): string {
    if (paymentDate) {
      return "legend-icon back-blue";
    }
  
    let today = new Date();
    // Usando new Date() para obter a data atual
    const daysDiff = this.dateDiffInDays(new Date(dueDate), today);
  
    if (daysDiff <= 0) {
      // Não vencido
      return "legend-icon back-green";
    } else if (new Date(dueDate) < today && daysDiff <= this.acceptDueDays) {
      // Vencido, mas dentro do limite de acceptDueDays
      return "legend-icon back-yellow";
    } else {
      // Vencido e fora do limite de acceptDueDays
      return "legend-icon back-red";
    }
  }

  public dateDiffInDays(a: Date, b: Date): number {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  public getPaymentColor(value: number): string {
    if (value < 0) {
      return "red card-content-value";
    } else {
      return "green card-content-value";
    }
  }

  calculateAndFormat(row: any): string {
    const originalValue = row.originalValue || 0;
    const paymentValue = row.paymentValue || row.total || 0;
    const result = originalValue - paymentValue;
  
    const today = new Date();
    const dueDate = new Date(row.dueDate); // Assumindo que row.dueDate é a data de vencimento
    const timeDiff = today.getTime() - dueDate.getTime();
    const dayDiff = timeDiff / (1000 * 3600 * 24);
   
 
    if (dayDiff > this.acceptDueDays && row.paymentDay == null) {
      return '-'
    }
  
    const displayValue = result > 0 ? -result : -result;
  
    return this.currencyPipe.transform(displayValue, "BRL", "symbol", "1.2-2");
  }

  public disabledValueActual(row: any) { 
    const today = new Date();
    const dueDate = new Date(row.dueDate); // Assumindo que row.dueDate é a data de vencimento
    const timeDiff = today.getTime() - dueDate.getTime();
    const dayDiff = timeDiff / (1000 * 3600 * 24);
    
 
    if (dayDiff > this.acceptDueDays && row.paymentDay == null) {
      return '-'
    }
    return this.currencyPipe.transform(row.paymentValue || row.total , "BRL", "symbol", "1.2-2");
  }


  public checkall(event , ignoreCondiction: (row: any) => boolean = () => false): void {
    this.table.data
      .filter((c) => !this.shouldDisableCheckbox(c) && !ignoreCondiction(c))
      .forEach((c) => {
        this.onRowChange(c, event, true);
      });
  }

  public getDatacy(key: string, payment?: any): string {
    let datacy = `${this.datacy}${key}`;
    if (payment) {
      datacy += payment.instalmentNumber || payment.number;
    }

    return datacy;
  }
}
